import React from "react";
import { Form } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";

const HwRegister = () => {
  return (
    <div>
      <dialog id="homewonerRegister_modal" className="modal">
        <div className="modal-box max-w-screen-md">
          <div className="modal-action pr-2 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
          <div className="flex flex-col text-center pt-4">
            <h3 className="text-xl font-semibold Organic_Black lg:text-3xl">
              Homewoner Registration form
            </h3>
            <p className="max-w-xl text-sm Organic_Black opacity-75 m-auto pt-2">
              To connect with qualified construction professionals and simplify
              your project journey, please complete this quick registration
              form.
            </p>
          </div>
          <div className="mt-12"></div>
          <Form>
            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="hwName"
                    className="mb-3 block text-base font-semibold Organic_Black "
                  >
                    Full Name
                  </label>
                  <input
                    required
                    type="text"
                    name="hwName"
                    id="hwName"
                    placeholder="Full Name"
                    className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="hwEmail"
                    className="mb-3 block text-base font-semibold Organic_Black"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="hwEmail"
                    id="hwEmail"
                    placeholder="Enter your email"
                    className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                  />
                </div>
              </div>
            </div>

            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="hwPhone"
                    className="mb-3 block text-base font-semibold Organic_Black"
                  >
                    Phone Number
                  </label>
                  <input
                    required
                    type="text"
                    name="hwPhone"
                    id="hwPhone"
                    placeholder="Enter your phone number"
                    className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="hwBDate"
                    className="mb-3 block text-base font-semibold Organic_Black"
                  >
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="hwBDate"
                    id="hwBDate"
                    className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                  />
                </div>
              </div>
            </div>

            <div className="mb-5 pt-3">
              <label className="mb-5 block text-base font-semibold Organic_Black sm:text-xl">
                Address Details
              </label>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      required
                      name="hwDivision"
                      id="hwDivision"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select Division
                      </option>
                      <option
                        value={"Dhaka"}
                        className="font-semibold text-slate-300"
                      >
                        Dhaka
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      required
                      name="hwDistrict"
                      id="hwDistrict"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select District
                      </option>
                      <option
                        value={"Dhaka"}
                        className="font-semibold text-slate-300"
                      >
                        Dhaka
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      required
                      name="hwThana"
                      id="hwThana"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select Thana
                      </option>
                      <option
                        value={"Savar"}
                        className="font-semibold text-slate-300"
                      >
                        Savar
                      </option>
                    </select>
                  </div>
                </div>

                <div className="w-full px-3 sm:w-3/3">
                  <div className="mb-5">
                    <textarea
                      name="hwAddress"
                      id="hwAddress"
                      cols="30"
                      rows="4"
                      className="h-30 w-full resize-none rounded-md border border-[#D1D1D1] p-5 text-base text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                      placeholder="Address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5 pt-3">
              <label className="mb-5 block text-base font-semibold Organic_Black sm:text-xl">
                Account Information
              </label>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-2/2">
                  <div className="mb-5">
                    <label
                      htmlFor="hwUsername"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Username
                    </label>
                    <input
                      required
                      type="text"
                      name="hwUsername"
                      id="hwUsername"
                      placeholder="Username will be your phone number"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="hwPassword"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Password
                    </label>
                    <input
                      required
                      type="password"
                      name="hwPassword"
                      id="hwPassword"
                      placeholder="Enter your password"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="hwConfirmPassword"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Confirm Password
                    </label>
                    <input
                      required
                      type="password"
                      name="hwConfirmPassword"
                      id="hwConfirmPassword"
                      placeholder="Confirm your password"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <div className="flex gap-3 items-center">
                <input
                  required
                  type="checkbox"
                  id="hwPrivacy"
                  name="hwPrivacy"
                  className="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                />
                <label htmlFor="hwPrivacy" className="space-x-2 text-sm inline">
                  I have read and accept the
                  <button
                    onClick={() =>
                      document.getElementById("privacyPolicy_modal").showModal()
                    }
                    className="link px-2 Orange_Yellow  hover:font-semibold "
                  >
                    Privacy statement
                  </button>
                </label>
              </div>
            </div>
            <div className="flex text-center justify-center pt-14 pb-8">
              <button className="text-xl sm:text-2xl hover:shadow-form w-3/6 rounded-md bg-[#F6AE28] py-3 px-4 sm:px-8 text-center font-semibold text-white outline-none">
                Register
              </button>
            </div>
          </Form>
        </div>
      </dialog>

      {/* privacy policy modal  */}
      <PrivacyPolicy />
    </div>
  );
};

export default HwRegister;
