import React from "react";
import { Form, Link } from "react-router-dom";
import PrRegister from "./PrRegister";

const PrLogin = () => {
  return (
    <div>
      <dialog id="providerLogin_modal" className="modal">
        <div className="modal-box">
          <div className="modal-action pr-2 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
          <div className="flex flex-col text-center">
            <p className="text-xl font-medium Organic_Black lg:text-3xl">
              Login to your account
            </p>
            <p className="text-md font-medium  lg:text-lg Accent_Red opacity-70">
              for Provider
            </p>
          </div>
          <Form className="mt-12">
            <div className="space-y-3">
              <div className="">
                <label
                  htmlFor="email"
                  className="block mb-3 text-sm font-medium text-black sr-only"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  placeholder="Email Address"
                  aria-placeholder="Email Adress"
                  className="block w-full h-12 px-4 py-3  bg-gray-100 border-0 rounded-lg appearance-none Organic_Black focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-inset text-xs"
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="password"
                  className="block mb-3 text-sm font-medium text-black sr-only"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Type password here..."
                  aria-placeholder="Type password here..."
                  className="block w-full h-12 px-4 py-3  bg-gray-100 border-0 rounded-lg appearance-none Organic_Black focus:bg-white focus:outline-none focus:ring-blue-500 focus:ring-inset text-xs"
                />
              </div>
              <div className="flex items-center justify-between py-4">
                <div className="flex items-center">
                  <input
                    id="pRemember-me"
                    type="checkbox"
                    name="remember-me"
                    className="w-4 h-4 accent-amber-400 rounded focus:ring-white focus:border-white"
                  />
                  <label
                    className="block ml-2 text-sm font-medium leading-tight Organic_Black"
                    htmlFor="remember-me"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <Link
                    className="font-medium Organic_Black hover:text-indigo-500"
                    to={"#"}
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div className="col-span-full">
                <Link
                  className="Orange_Yellow_bg Organic_Black capitalize flex items-center justify-center h-10 px-4 py-2 font-semibold transition-all duration-200 rounded-full hover:bg-amber-500"
                  // className="flex items-center justify-center h-10 px-4 py-2 text-base font-semibold text-white transition-all duration-200 rounded-full bg-gradient-to-b from-blue-500 to-indigo-600 hover:to-indigo-700 shadow-button shadow-blue-600/50 focus:ring-2 focus:ring-blue-950 focus:ring-offset-2 ring-offset-gray-200 hover:shadow-none"
                  to={"#"}
                >
                  Log in
                </Link>
              </div>
            </div>
            <div className="mt-3 Organic_Black">
              <label htmlFor="remember-me">Want to join BMCportal?</label>
              <Link
                to={"#"}
                onClick={() =>
                  document.getElementById("providerRegister_modal").showModal()
                }
                className="hover:link px-2 Orange_Yellow font-semibold hover:font-bold "
              >
                Sign Up
              </Link>
            </div>
          </Form>
        </div>
      </dialog>
      {/* modal section  */}
      <PrRegister />
    </div>
  );
};

export default PrLogin;
