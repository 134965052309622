import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//pages
import Users from "./pages/Users";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Providers from "./pages/Providers";
import Projects from "./pages/Projects";
import Resources from "./pages/Resources";
import Support from "./pages/Support";
import Account from "./pages/Account";
import ErrorPage from "./pages/ErrorPage";
import ServiceDetails from "./pages/ServiceDetails";
import ServiceCategory from "./pages/ServiceCategory";
import ServiceCategoryDetails from "./pages/ServiceCategoryDetails";
import ServicesAll from "./pages/ServicesAll";

import ChatBox from "./components/ChatBox";

import Plumber from "./components/sevices/Plumber";
import PlumberDetails from "./components/sevices/PlumberDetails";
import Electrician from "./components/sevices/Electrician";
import ElectricianDetails from "./components/sevices/ElectricianDetails";
import TilesDetails from "./components/sevices/TilesDetails";
import Tiles from "./components/sevices/Tiles";
import Painter from "./components/sevices/Painter";
import PainterDetails from "./components/sevices/PainterDetails";
import Tutorial from "./pages/Tutorial";
import Submenu from "./components/card/Submenu";
import AllPopularService from "./pages/AllPopularService";
import ProviderDashboard from "./pages/ProviderDashboard";
import Main from "./pages/Main";
import Loading from "./components/Loading";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<Main />}>
        <Route index element={<Home />}/>
        <Route path="users" element={<Users />} />
        <Route path="services" element={<Services />} />

        <Route path="services/plumber" element={<Plumber />} />
        <Route path="services/plumber/plumber1" element={<PlumberDetails />} />
        <Route path="services/electrician" element={<Electrician />} />
        <Route
          path="services/electrician/electrician1"
          element={<ElectricianDetails />}
        />
        <Route path="services/tiles" element={<Tiles />} />
        <Route path="services/tiles/tiles1" element={<TilesDetails />} />
        <Route path="services/painter" element={<Painter />} />
        <Route path="services/painter/painter1" element={<PainterDetails />} />

        <Route path="services/all" element={<ServicesAll />} />
        <Route path="services/all/:sdId" element={<ServiceDetails />} />
        <Route path="services/category" element={<ServiceCategory />} />
        <Route path="services/all" element={<ServiceCategory />} />
        <Route
          path="services/category/:sName"
          element={<ServiceCategoryDetails />}
        />
        <Route
          path="services/category/:id/:sdId"
          element={<ServiceDetails />}
        />
        <Route path="items/:id" element={<ServiceDetails />} />
        <Route path="providers" element={<Providers />} />
        <Route path="projects" element={<Projects />} />
        <Route path="resources" element={<Resources />} />
        <Route path="support" element={<Support />} />
        <Route path="account" element={<Account />} />
        <Route path="chat-box" element={<ChatBox />} />
        <Route path="tutorial" element={<Tutorial />} />
        <Route path="submenu" element={<Submenu />} />
        

        <Route path="popular-service" element={<AllPopularService />} />
      </Route>
      <Route path="provider-dashboard" element={<ProviderDashboard />} />
        <Route path="loading" element={<Loading />} />

      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
