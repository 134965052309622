import "./App.css";
import Navbar from "./components/Navbar";
import { Outlet, useNavigation } from "react-router-dom";
// import React, { lazy, Suspense } from 'react';
// const OtherComponent = lazy(() => import('./OtherComponent'));


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

//components
import Footer from "./components/Footer";
import Loading from "./components/Loading";


function App() {
  const navigation = useNavigation();
  
  return (
    <>
      <div className="overflow-x-hidden">
      {/* {navigation.state === "loading" && <Loading />} */}
      {/* <Suspense fallback={<div>Loading...</div>}>
        <OtherComponent />
      </Suspense> */}

        <Outlet />
      </div>
    </>
  );
}

export default App;
