import React, { useEffect } from "react";
import { Form } from "react-router-dom";

const QuotationRequest = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <dialog id="quotationRequest_modal" className="modal  ">
        <div className="modal-box max-w-screen-md bg-gray-100 pt-5 ">
          <div className="modal-action pr-5 mt-0">
            <form method="dialog">
              <button className="p-1 Organic_Black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </form>
          </div>

          <div className="flex flex-col text-center pt-4">
            <h3 className="text-xl font-semibold Organic_Black lg:text-3xl">
              Qutation Request form
            </h3>
            <p className="max-w-xl text-sm Organic_Black opacity-75 m-auto pt-2">
              Please give desied projects information which the concerned
              providers can see and then quote a budget.
            </p>
          </div>
          <div className="mt-12"></div>
          <Form>
            <div className="-mx-3 flex flex-wrap ">
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="serviceType"
                    className="mb-3 block text-base font-semibold Organic_Black "
                  >
                    Service Type
                  </label>
                  <select
                    required
                    name="serviceType"
                    id="serviceType"
                    className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                  >
                    <option className="font-semibold text-slate-300">
                      Select Type
                    </option>
                    <option
                      value={"Construction Material"}
                      className="font-semibold text-slate-300"
                    >
                      Construction Material
                    </option>
                    <option
                      value={"Electrical Equipment"}
                      className="font-semibold text-slate-300"
                    >
                      Electrical Equipment
                    </option>
                    <option
                      value={"Furniture & Decoration"}
                      className="font-semibold text-slate-300"
                    >
                      Furniture & Decoration
                    </option>
                  </select>
                </div>
              </div>
              <div className="w-full px-3 sm:w-1/2">
                <div className="mb-5">
                  <label
                    htmlFor="totalArea"
                    className="mb-3 block text-base font-semibold Organic_Black "
                  >
                    Total Area - Sq.Ft
                  </label>
                  <input
                    required
                    type="text"
                    name="totalArea"
                    id="totalArea"
                    placeholder="Type Total Area - Square Feet"
                    className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                  />
                </div>
              </div>
            </div>

            <div className="mb-5 pt-3">
              <label
                htmlFor="sdivision"
                className="mb-3 block text-base font-semibold Organic_Black "
              >
                Service Location
              </label>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      name="sdivision"
                      id="sdivision"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select Division
                      </option>
                      <option
                        value={"Dhaka"}
                        className="font-semibold text-slate-300"
                      >
                        Dhaka
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      name="sdistrict"
                      id="sdistrict"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select District
                      </option>
                      <option
                        value={"Dhaka"}
                        className="font-semibold text-slate-300"
                      >
                        Dhaka
                      </option>
                    </select>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/3">
                  <div className="mb-5">
                    <select
                      name="sthana"
                      id="sthana"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    >
                      <option className="font-semibold text-slate-300">
                        Select Thana
                      </option>
                      <option
                        value={"Savar"}
                        className="font-semibold text-slate-300"
                      >
                        Savar
                      </option>
                    </select>
                  </div>
                </div>

                <div className="w-full px-3 sm:w-3/3">
                  <div className="mb-5">
                    <textarea
                      name="prAddress"
                      id="prAddress"
                      cols="30"
                      rows="4"
                      className="h-20 w-full resize-none rounded-md border border-[#D1D1D1] p-3 text-base text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                      placeholder="Full Address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-5 pt-3">
              <div className="-mx-3 flex flex-wrap">
                
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="startingDate"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Tentative Starting Date
                    </label>
                    <input
                      required
                      type="date"
                      name="startingDate"
                      id="startingDate"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="projectDuration"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Expected Project Duration
                    </label>
                    <div className="flex items-end">
                      <div className="w-24 mr-3">
                        <input
                          required
                          type="text"
                          name="projectDuration"
                          id="projectDuration"
                          placeholder="Duration"
                          className="w-full text-center bg-transparent border border-[#000000] border-x-0 border-t-0  py-2 px-2 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                        />
                      </div>
                      <div className="pb-2">
                        <input
                          type="radio"
                          id="months"
                          name="projectDurationType"
                          value="months"
                          className=" outline-none focus:border-[#F6AE28] focus:shadow-md"
                        />

                        <label
                          for="months"
                          className="py-2 px-1 text-base font-medium text-[#191308]"
                        >
                          Months
                        </label>
                      </div>
                      <div className="ml-5 pb-2">
                        <input
                          type="radio"
                          id="days"
                          name="projectDurationType"
                          value="days"
                          className=" outline-none focus:border-[#F6AE28] focus:shadow-md"
                        />

                        <label
                          for="days"
                          className="py-2 px-1 text-base font-medium text-[#191308]"
                        >
                          Days
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      htmlFor="projectBudget"
                      className="mb-3 block text-base font-semibold Organic_Black"
                    >
                      Project Budget
                    </label>
                    <input
                      required
                      type="text"
                      name="projectBudget"
                      id="projectBudget"
                      placeholder="Enter your budget"
                      className="w-full rounded-md border border-[#D1D1D1] bg-white py-3 px-6 text-base placeholder:text-[#D1D1D1] font-medium text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full ">
                  <div className="mb-5">
                    <label
                      htmlFor="description"
                      className="mb-3 block text-base font-semibold Organic_Black "
                    >
                      Other Specific Instruction
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      cols="30"
                      rows="4"
                      className="h-30 w-full resize-none rounded-md border border-[#D1D1D1] p-5 text-base text-[#191308] outline-none focus:border-[#F6AE28] focus:shadow-md"
                      placeholder="Other specific instruction"
                    ></textarea>
                  </div>
                </div>
            </div>
            
            
            <div className="flex text-center justify-center pt-10 pb-8">
              <button className="text-xl sm:text-2xl hover:shadow-form w-3/6 rounded-md bg-[#F6AE28] py-3 px-4 sm:px-8 text-center font-semibold text-white outline-none">
                Submit
              </button>
            </div>
          </Form>
        </div>
      </dialog>
    </div>
  );
};

export default QuotationRequest;
